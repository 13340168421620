<div class="workflow-picker-preview">
  <div class="workflow-picker-preview__details">
    <div class="workflow-picker-preview__details-name">
      {{ workflow().name }}
    </div>

    <div class="workflow-picker-preview__details-metadata">
      <div
        class="workflow-picker-preview__details-metadata-item"
        [innerHTML]="'workflowPicker.preview.fromFolder' | translate: { name: folder().name }"
      ></div>
      <div
        class="workflow-picker-preview__details-metadata-item"
        [innerHTML]="
          'workflowPicker.preview.lastUpdatedAgo' | translate: { ago: workflow().modifiedAt | ccDateFormatDistance }
        "
      ></div>
    </div>
  </div>

  <div class="workflow-picker-preview__edit-button">
    <app-button
      [ccTooltip]="'workflowPicker.preview.editWorkflow' | translate"
      [type]="ButtonType.GhostText"
      [size]="ButtonSize.Small"
      (click)="edit.emit()"
    >
      <i
        class="icon-edit-pencil"
        slot="ghost-icon"
      ></i>
    </app-button>
  </div>
</div>
