import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Injector,
  input,
  model,
  signal,
  type OnInit,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgScrollbar } from 'ngx-scrollbar';
import { BehaviorSubject, catchError, combineLatest, map, of, startWith, switchMap, tap, type Observable } from 'rxjs';

import {
  PagingOrder,
  sortDirectionToPagingOrder,
  type OffsetPagingWrapper,
  type SortingOptions,
} from '@clover/core/helpers/paging';
import {
  FilterableWorkflowStatus,
  type WorkflowPreview,
  type WorkflowType,
} from '@clover/workflows-v2/state/workflows-state.model';
import { WorkflowsService, type WorkflowsSortingProperty } from '@clover/workflows-v2/state/workflows.service';
import type { SortDirection } from '@design/table/table';
import { TableComponent } from '@design/table/table.component';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';

import { WorkflowTableRowComponent } from '../../../../../../app/workflows-v2/workflows-list/workflows-table/workflow-table-row/workflow-table-row.component';

@UntilDestroy()
@Component({
  selector: 'cc-workflows-picker-dialog-list-table',
  standalone: true,
  imports: [
    NgScrollbar,
    InfiniteScrollDirective,
    TableComponent,
    TrComponent,
    ThComponent,
    WorkflowTableRowComponent,
    AsyncPipe,
    TranslateModule,
  ],
  templateUrl: './workflows-picker-dialog-list-table.component.html',
  styleUrl: './workflows-picker-dialog-list-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowsPickerDialogListTableComponent implements OnInit {
  selectedWorkflowId = model<number | undefined>(undefined);
  workflowFolderId = input<number | undefined>(undefined);
  allowedWorkflowType = input<WorkflowType | undefined>(undefined);

  protected searchFormControl = new FormControl<string>('');
  protected sortingOptions$ = new BehaviorSubject<SortingOptions<WorkflowsSortingProperty>>({
    orderBy: 'name',
    order: PagingOrder.Ascending,
  });

  protected nameSortingOrder$: Observable<SortDirection>;
  protected typeSortingOrder$: Observable<SortDirection>;

  protected loadedWorkflows = signal<OffsetPagingWrapper<WorkflowPreview> | undefined>(undefined);
  protected loadingStatus = signal<'void' | 'loaded' | 'loading' | 'loading-next' | 'error'>('void');

  protected readonly workflows = computed(() => this.loadedWorkflows()?.data ?? []);
  protected readonly workflowsTotal = computed(() => this.loadedWorkflows()?.total ?? 0);

  private readonly workflowsService = inject(WorkflowsService);
  private readonly injector = inject(Injector);

  ngOnInit(): void {
    this.nameSortingOrder$ = this.sortingOptions$.pipe(
      map((sortingOptions) => (sortingOptions.orderBy === 'name' ? sortingOptions.order : null)),
    );
    this.typeSortingOrder$ = this.sortingOptions$.pipe(
      map((sortingOptions) => (sortingOptions.orderBy === 'type' ? sortingOptions.order : null)),
    );

    this.initWorkflows();
  }

  handleWorkflowSelect(workflowId: number): void {
    this.selectedWorkflowId.set(workflowId);
  }

  handleSearchChange(query: string): void {
    this.searchFormControl.setValue(query);
  }

  changeSort(property: WorkflowsSortingProperty, direction: SortDirection) {
    this.sortingOptions$.next({
      orderBy: property,
      order: sortDirectionToPagingOrder(direction),
    });
  }

  loadNextWorkflows(): void {
    if (this.loadingStatus() !== 'loaded') return;
    this.loadingStatus.set('loading-next');

    this.workflowsService
      .searchWorkflows(
        this.searchFormControl.value,
        {
          folderId: this.workflowFolderId(),
          status: FilterableWorkflowStatus.Published,
        },
        {
          limit: 30,
          offset: this.loadedWorkflows().count,
          order: this.sortingOptions$.value.order,
          orderBy: this.sortingOptions$.value.orderBy,
        },
      )
      .pipe(
        untilDestroyed(this),
        tap((response: OffsetPagingWrapper<WorkflowPreview>) => {
          this.loadedWorkflows.set({
            ...this.loadedWorkflows(),
            data: this.loadedWorkflows().data.concat(response.data),
            count: this.loadedWorkflows().count + response.count,
            paging: response.paging,
          });

          this.loadingStatus.set('loaded');
        }),
        catchError(() => {
          this.loadingStatus.set('error');
          return of();
        }),
      )
      .subscribe();
  }

  private initWorkflows(): void {
    combineLatest([
      toObservable(this.workflowFolderId, {
        injector: this.injector,
      }),
      this.searchFormControl.valueChanges.pipe(startWith('')),
      this.sortingOptions$,
    ])
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.loadingStatus.set('loading');
        }),
        switchMap(([folderId, query, sorting]) => {
          return this.workflowsService.searchWorkflows(
            query,
            {
              folderId,
              status: FilterableWorkflowStatus.Published,
            },
            {
              limit: 30,
              order: sorting.order,
              orderBy: sorting.orderBy,
            },
          );
        }),
        tap((response: OffsetPagingWrapper<WorkflowPreview>) => {
          this.loadedWorkflows.set(response);
          this.loadingStatus.set('loaded');
        }),
        catchError(() => {
          this.loadingStatus.set('error');
          return of();
        }),
      )
      .subscribe();
  }
}
