<div class="vendor-association-dialog">
  <div class="vendor-association-dialog__header">
    <div class="vendor-association-dialog__header-title">
      <div class="vendor-association-dialog__header-title-text">Supplier Associations</div>
    </div>

    <div class="vendor-association-dialog__header-description">
      To assign tasks for this product, correct the missing or invalid supplier associations below.
    </div>
  </div>

  <ng-scrollbar class="vendor-association-dialog__content--scrollbar">
    <div class="vendor-association-dialog__content">
      <cc-task-assignment-product-picker-dialog-preview
        [product]="product"
        [supplierColumn]="supplierColumn"
      />

      <ng-template #supplierColumn>
        <div class="vendor-association-dialog__content-supplier-column">
          <div class="vendor-association-dialog__content-supplier-column-details">
            @if (vendorFormControl.value; as fixedVendor) {
              <div class="vendor-association-dialog__content-supplier-column-details-textbox">
                {{ fixedVendor.name }}
              </div>
            } @else {
              @if (product.vendor.companyName || product.vendor.internalCompanyName; as vendorName) {
                <div
                  class="vendor-association-dialog__content-supplier-column-details-textbox vendor-association-dialog__content-supplier-column-details-textbox--error"
                >
                  {{ vendorName }}
                </div>

                <div class="vendor-association-dialog__content-supplier-column-details-error">
                  <i class="icon-error-fill"></i>
                  <span>Unknown supplier</span>
                </div>
              } @else {
                <div class="vendor-association-dialog__content-supplier-column-details-error">
                  <i class="icon-error-fill"></i>
                  <span>Missing supplier</span>
                </div>
              }
            }
          </div>

          <app-button
            (click)="fixSupplier()"
            ccTooltip="Specify supplier"
            [type]="ButtonType.Ghost"
            [size]="ButtonSize.Big"
          >
            <i
              class="icon-search"
              slot="ghost-icon"
            ></i>
          </app-button>
        </div>
      </ng-template>
    </div>
  </ng-scrollbar>

  <div class="vendor-association-dialog__actions">
    <app-button
      (click)="dialogRef.close()"
      [type]="ButtonType.Secondary"
      [size]="ButtonSize.Small"
    >
      {{ 'common.buttons.cancel' | translate }}
    </app-button>

    <app-button
      (click)="save()"
      [disabled]="vendorFormControl.invalid"
      [type]="ButtonType.Primary"
      [size]="ButtonSize.Small"
    >
      {{ 'common.buttons.save' | translate }}
    </app-button>
  </div>
</div>
