import { CdkScrollable } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, computed, inject, input, model, signal, type OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgScrollbar } from 'ngx-scrollbar';
import { catchError, combineLatest, of, startWith, switchMap, tap } from 'rxjs';

import type { OffsetPagingWrapper } from '@clover/core/helpers/paging';
import type { AssociatedCompany } from '@clover/network-v2/network/accounts/state/accounts.entities';
import { CompanyListsService } from '@clover/network-v2/network/lists/company-lists.service';
import { TableComponent } from '@design/table/table.component';
import { TdComponent } from '@design/table/td/td.component';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';

import { ConnectionStatus } from './../../../../app/core/models/company';
import { CompanyCardComponent } from '../../../../app/network-v2/network/accounts/company-card/company-card.component';

@UntilDestroy()
@Component({
  selector: 'cc-company-picker-dialog-table',
  standalone: true,
  imports: [
    NgScrollbar,
    TableComponent,
    TranslateModule,
    TrComponent,
    ThComponent,
    TdComponent,
    CompanyCardComponent,
    InfiniteScrollDirective,
    CdkScrollable,
  ],
  templateUrl: './company-picker-dialog-table.component.html',
  styleUrl: './company-picker-dialog-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyPickerDialogTableComponent implements OnInit {
  selectedCompany = model.required<AssociatedCompany>();
  connectionStatuses = input<Array<ConnectionStatus.Connected | ConnectionStatus.Invited> | undefined>(undefined);

  protected searchFormControl = new FormControl<string>('');

  protected loadedCompanies = signal<OffsetPagingWrapper<AssociatedCompany> | undefined>(undefined);
  protected loadingStatus = signal<'void' | 'loaded' | 'loading' | 'loading-next' | 'error'>('void');

  protected readonly companies = computed(() => this.loadedCompanies()?.data ?? []);
  protected readonly companiesTotal = computed(() => this.loadedCompanies()?.total ?? 0);

  private readonly companyListsService = inject(CompanyListsService);

  ngOnInit(): void {
    this.initCompanies();
  }

  handleCompanySelect(company: AssociatedCompany): void {
    this.selectedCompany.set(company);
  }

  handleSearchChange(query: string): void {
    this.searchFormControl.setValue(query);
  }

  loadNextCompanies(): void {
    if (this.loadingStatus() !== 'loaded') return;
    this.loadingStatus.set('loading-next');

    this.companyListsService
      .searchCompanies({
        query: this.searchFormControl.value,
        limit: 30,
        offset: this.loadedCompanies().count,
        connectionStatuses: this.connectionStatuses(),
      })
      .pipe(
        untilDestroyed(this),
        tap((response: OffsetPagingWrapper<AssociatedCompany>) => {
          this.loadedCompanies.set({
            ...this.loadedCompanies(),
            data: this.loadedCompanies().data.concat(response.data),
            count: this.loadedCompanies().count + response.count,
            paging: response.paging,
          });

          this.loadingStatus.set('loaded');
        }),
        catchError(() => {
          this.loadingStatus.set('error');
          return of();
        }),
      )
      .subscribe();
  }

  private initCompanies(): void {
    combineLatest([this.searchFormControl.valueChanges.pipe(startWith(''))])
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.loadingStatus.set('loading');
        }),
        switchMap(([query]) => {
          return this.companyListsService.searchCompanies({
            query,
            offset: 0,
            limit: 30,
            connectionStatuses: this.connectionStatuses(),
          });
        }),
        tap((response: OffsetPagingWrapper<AssociatedCompany>) => {
          this.loadedCompanies.set(response);
          this.loadingStatus.set('loaded');
        }),
        catchError(() => {
          this.loadingStatus.set('error');
          return of();
        }),
      )
      .subscribe();
  }
}
