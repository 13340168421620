import { trigger, transition, style, animate } from '@angular/animations';

export const drawerAnimation = trigger('drawerAnimation', [
  transition(':enter', [
    style({ width: '0', minWidth: '0' }),
    animate('0.2s ease-in-out', style({ width: '*', minWidth: 'unset' })),
  ]),
  transition(':leave', [
    style({ width: '*', minWidth: 'unset' }),
    animate('0.2s ease-in-out', style({ width: '0', minWidth: '0' })),
  ]),
]);
