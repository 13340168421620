import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import type { AssociatedCompany } from '@clover/network-v2/network/accounts/state/accounts.entities';
import { humanizeLocation } from '@design/overlays/company-location-editor/location.helpers';

import { ChipComponent } from '../../../../chips/chip/chip.component';

@Component({
  selector: 'cc-company-drawer-details',
  standalone: true,
  imports: [TranslateModule, ChipComponent],
  templateUrl: './company-drawer-details.component.html',
  styleUrl: './company-drawer-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyDrawerDetailsComponent {
  company = input.required<AssociatedCompany>();

  protected readonly companyType = computed(() => {
    return this.company().companyType?.title || undefined;
  });

  protected readonly industries = computed(() => {
    const industries = this.company().subIndustries?.industries || [];

    if (!industries.length) return undefined;
    return industries;
  });

  protected readonly numberOfEmployees = computed(() => {
    return this.company().numberOfEmployeesType?.title || undefined;
  });

  protected readonly annualRevenue = computed(() => {
    return this.company().annualRevenue?.title || undefined;
  });

  protected readonly description = computed(() => {
    return this.company().description || undefined;
  });

  protected readonly address = computed(() => {
    const address = this.company().address;
    if (!address) return undefined;

    return humanizeLocation(address);
  });

  protected readonly website = computed(() => {
    return this.company().websiteUrl || undefined;
  });
}
