<div
  class="workflow-picker-dialog-folders"
  [ccAutoAnimate]
>
  @if (loadingStatus() === 'loaded') {
    @for (folder of folders(); track folder.id) {
      <cc-subnav-item
        appearance="tinted"
        (click)="selectFolder(folder.id)"
        [title]="folder.name"
        iconClass="icon-files"
        [selected]="folder.id === selectedFolderId()"
      />
    }
  } @else {
    <div class="workflow-picker-dialog-folders__loader">
      <i class="icon-files-fill"></i>
    </div>
  }
</div>
