<ng-scrollbar
  class="workflow-picker-dialog-list-table--scrollbar"
  (scrolled)="loadNextWorkflows()"
  [infiniteScrollDistance]="4"
  [infiniteScrollThrottle]="0"
  [infiniteScrollUpDistance]="4"
  [scrollWindow]="false"
  cdkScrollable
  infiniteScroll
>
  <div class="workflows-picker-dialog-list-table">
    <cc-table
      (searchChange)="handleSearchChange($event)"
      [ccSearchable]="true"
      [ccSearchPlaceholder]="'workflowPicker.searchPlaceholder' | translate"
      [ccEmptyRowTemplate]="emptyRowTemplate"
      class="cc-table"
      ccPadding="small"
    >
      <ng-container slot="cc-table-title">
        @if (loadingStatus() === 'loading' && !loadedWorkflows()) {
          <span class="cc-ellipsis-animation">
            {{ 'common.strings.loading' | translate }}
          </span>
        } @else {
          @if (workflowsTotal() === 1) {
            {{ 'workflowPicker.workflowsCountSingular' | translate }}
          } @else {
            {{ 'workflowPicker.workflowsCountPlural' | translate: { count: workflowsTotal() } }}
          }
        }
      </ng-container>

      <ng-container slot="cc-table">
        <thead>
          <tr ccTr>
            <th
              ccTh
              ccWidth="50%"
              ccMinWidth="50%"
              [ccSortable]="true"
              [ccSortDirection]="nameSortingOrder$ | async"
              (ccSortChange)="changeSort('name', $event)"
            >
              {{ 'workflowPicker.columns.workflow' | translate }}
            </th>
            <th
              ccTh
              ccWidth="50%"
              ccMinWidth="50%"
              [ccSortable]="true"
              [ccSortDirection]="typeSortingOrder$ | async"
              (ccSortChange)="changeSort('type', $event)"
            >
              {{ 'workflowPicker.columns.type' | translate }}
            </th>
          </tr>
        </thead>

        <tbody>
          @for (workflow of workflows(); track workflow.id) {
            <tr
              (click)="handleWorkflowSelect(workflow.id)"
              [ccClickable]="true"
              [ccDisabled]="allowedWorkflowType() && workflow.type !== allowedWorkflowType()"
              [ccSelected]="selectedWorkflowId() === workflow.id"
              ccTr
            >
              <cc-workflow-table-row
                [workflow]="workflow"
                [showStatusColumn]="false"
                [showActionsColumn]="false"
              />
            </tr>
          }

          <ng-template #emptyRowTemplate>
            @if (loadingStatus() === 'loading') {
              {{ 'workflowPicker.loading' | translate }}
            } @else {
              {{ 'workflowPicker.noWorkflows' | translate }}
            }
          </ng-template>
        </tbody>
      </ng-container>
    </cc-table>
  </div>
</ng-scrollbar>
