<td ccTd>
  <div class="contact-name-column">
    @if (selectable()) {
      <app-checkbox
        (change)="handleSelectChange($event)"
        [checked]="selected()"
      />
    }

    <div class="contact-name-column__details">
      <cc-user-avatar
        [src]="contact().avatarUrl"
        [id]="contact().id"
        [name]="[contact().firstName, contact().lastName]"
        [size]="24"
      />

      <div class="contact-name-column__details-name">{{ contact().firstName }} {{ contact().lastName }}</div>
    </div>
  </div>
</td>

<td ccTd>
  <div class="contact-email-column">
    {{ contact().email }}
  </div>
</td>
