<div class="company-task-assignment-dialog">
  <div class="company-task-assignment-dialog__header">
    <div class="company-task-assignment-dialog__header-title">
      <div class="company-task-assignment-dialog__header-title-text">
        {{ 'taskAssignment.common.taskAssignment' | translate }}
      </div>

      <div class="company-task-assignment-dialog__header-title-chip">
        {{ 'taskAssignment.companyModal.chip' | translate }}
      </div>
    </div>

    <div class="company-task-assignment-dialog__header-description">
      {{ 'taskAssignment.companyModal.description' | translate }}
    </div>
  </div>

  <ng-scrollbar class="company-task-assignment-dialog__content--scrollbar">
    <div class="company-task-assignment-dialog__content">
      <cc-task-assignment-workflow-picker
        [workflowId]="selectedWorkflowId"
        [allowedWorkflowType]="WorkflowType.Company"
        (workflowIdChange)="handleWorkflowChange($event)"
        (childDialogOpened)="handleChildDialogOpened()"
        (childDialogClosed)="handleChildDialogClosed()"
      />

      <cc-task-assignment-assignee-picker
        [companyId]="dialogData.companyId"
        [audience]="selectedAudience"
        (audienceChange)="handleAudienceChange($event)"
        (childDialogOpened)="handleChildDialogOpened()"
        (childDialogClosed)="handleChildDialogClosed()"
      />
    </div>
  </ng-scrollbar>

  <div class="company-task-assignment-dialog__actions">
    <app-button
      (click)="dialogRef.close()"
      [type]="ButtonType.Secondary"
      [size]="ButtonSize.Small"
    >
      {{ 'common.buttons.cancel' | translate }}
    </app-button>

    <app-button
      (click)="assignTask()"
      [disabled]="form.invalid"
      [type]="ButtonType.Primary"
      [size]="ButtonSize.Small"
    >
      {{ 'taskAssignment.common.assignTask' | translate }}
    </app-button>
  </div>
</div>
