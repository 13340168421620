<cc-table
  ccPadding="small"
  [ccSearchable]="true"
  [ccSearchPlaceholder]="'assigneePicker.preview.contacts.searchPlaceholder' | translate"
  [ccEmptyRowTemplate]="noContacts"
  [ccSearchDebounceTime]="0"
  (searchChange)="searchQuery.set($event)"
>
  <ng-container slot="cc-table">
    <thead>
      <tr ccTr>
        <th ccTh>
          {{ 'assigneePicker.preview.contacts.columns.company' | translate }}
        </th>

        <th ccTh>
          {{ 'assigneePicker.preview.contacts.columns.assignee' | translate }}
        </th>

        <th
          ccTh
          ccWidth="48px"
        ></th>
      </tr>
    </thead>

    <tbody>
      @for (contact of filteredContacts(); track contact.id) {
        <tr ccTr>
          <td ccTd>
            <div class="contact-company">
              <img
                [src]="contact.company.logoUrl || 'assets/svg/common/new-company-placeholder.svg'"
                [alt]="contact.company.name"
                class="contact-company__logo"
              />

              <div class="contact-company__name">{{ contact.company.name }}</div>
            </div>
          </td>

          <td ccTd>
            <div class="contact-details">
              <cc-user-avatar
                [src]="contact.avatarUrl"
                [id]="contact.id"
                [name]="[contact.firstName, contact.lastName]"
                [size]="24"
              />

              <div class="contact-details__name">{{ contact.firstName }} {{ contact.lastName }}</div>
            </div>
          </td>

          <td ccTd>
            <app-button
              [ccTooltip]="'common.buttons.moreActions' | translate"
              [tooltipAlignment]="TooltipAlignment.End"
              [ccDropdownTrigger]="dropdown"
              [ccDropdownPositions]="{
                offsetY: 6,
                positions: ['bottom-end', 'top-end'],
              }"
              [type]="ButtonType.Ghost"
              [size]="ButtonSize.Small"
            >
              <i
                class="icon-more"
                slot="ghost-icon"
              ></i>
            </app-button>

            <ng-template #dropdown>
              <cc-dropdown width="160px">
                <cc-dropdown-action (click)="contactRemove.emit(contact.id)">
                  <ng-container slot="title">
                    {{ 'assigneePicker.preview.contacts.remove' | translate }}
                  </ng-container>
                </cc-dropdown-action>
              </cc-dropdown>
            </ng-template>
          </td>
        </tr>
      }
    </tbody>
  </ng-container>
</cc-table>

<ng-template #noContacts>
  {{ 'assigneePicker.preview.contacts.noContacts' | translate }}
</ng-template>
