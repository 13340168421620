<div class="workflow-picker-dialog">
  <div class="workflow-picker-dialog__header">
    <div class="workflow-picker-dialog__header-main">
      <div class="workflow-picker-dialog__header-main-title">
        {{ 'workflowPicker.workflowLibrary' | translate }}
      </div>

      <app-button
        (click)="dialogRef.close()"
        [ccTooltip]="'common.buttons.close' | translate"
        [size]="ButtonSize.Big"
        [type]="ButtonType.Ghost"
      >
        <i
          class="icon-close"
          slot="ghost-icon"
        ></i>
      </app-button>
    </div>
  </div>

  <div class="workflow-picker-dialog__content">
    <div class="workflow-picker-dialog__content-folders">
      <ng-scrollbar
        orientation="vertical"
        class="workflow-picker-dialog__content-folders--scrollbar"
      >
        <div class="workflow-picker-dialog__content-folders-inner">
          <cc-workflow-picker-dialog-folders
            [(selectedFolderId)]="selectedFolderId"
            (foldersPopulated)="foldersPopulated.set(false)"
          />
        </div>
      </ng-scrollbar>
    </div>

    @if (!foldersPopulated()) {
      <cc-workflows-picker-dialog-list
        [allowedWorkflowType]="dialogData.allowedWorkflowType"
        (selectWorkflow)="selectWorkflow()"
        [(selectedWorkflowId)]="selectedWorkflowId"
        [workflowFolderId]="selectedFolderId()"
        class="workflow-picker-dialog__content-list"
      />
    }
  </div>
</div>
