<div class="company-picker-dialog">
  <div class="company-picker-dialog__header">
    <div class="company-picker-dialog__header-main">
      <div class="company-picker-dialog__header-main-title">Select company</div>

      <app-button
        (click)="dialogRef.close()"
        [ccTooltip]="'common.buttons.close' | translate"
        [size]="ButtonSize.Big"
        [type]="ButtonType.Ghost"
      >
        <i
          class="icon-close"
          slot="ghost-icon"
        ></i>
      </app-button>
    </div>
  </div>

  <div class="company-picker-dialog__content">
    <cc-company-picker-dialog-table
      class="company-picker-dialog__content-table"
      [(selectedCompany)]="selectedCompany"
      [connectionStatuses]="dialogData.connectionStatuses"
    />

    @if (selectedCompany(); as company) {
      <div @drawerAnimation>
        <cc-company-drawer
          class="company-picker-dialog__content-drawer"
          [company]="company"
          [showCloseButton]="false"
        >
          <app-button
            (click)="save()"
            [type]="ButtonType.Primary"
            [size]="ButtonSize.Small"
          >
            {{ 'companyDrawer.selectCompany' | translate }}
          </app-button>
        </cc-company-drawer>
      </div>
    }
  </div>
</div>
