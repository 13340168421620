import { ChangeDetectionStrategy, Component, inject, model, signal, type OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { take } from 'rxjs';

import {
  TempProductsService,
  type Product,
} from '@clover/conversations-v4/tasks/task-assignment/product-dialog/vendor-association-dialog/temp-products.service';
import { AutoAnimateDirective } from '@clover/core/directives/auto-animate.directive';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';

import { TaskAssignmentAssigneePickerDialogContactsPreviewTableComponent } from './product-picker-preview/product-picker-preview.component';
import { ButtonComponent } from '../../buttons/button/button.component';

type ProductPickerPreviewData = Product;

@UntilDestroy()
@Component({
  selector: 'cc-task-assignment-product-picker',
  standalone: true,
  imports: [
    ButtonComponent,
    AutoAnimateDirective,
    TranslateModule,
    TaskAssignmentAssigneePickerDialogContactsPreviewTableComponent,
  ],
  templateUrl: './product-picker.component.html',
  styleUrl: './product-picker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskAssignmentProductPickerComponent implements OnInit {
  productId = model.required<number | undefined>();
  vendorId = model.required<number | undefined>();

  protected readonly previewData = signal<ProductPickerPreviewData | undefined>(undefined);
  protected readonly previewDataLoading = signal<boolean>(false);

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;

  private readonly productsService = inject(TempProductsService);

  ngOnInit(): void {
    this.loadPreviewData();
  }

  private loadPreviewData(): void {
    const productId = this.productId();
    const vendorId = this.vendorId();
    this.previewData.set(undefined);

    if (!productId || !vendorId) return;

    this.previewDataLoading.set(true);

    this.productsService
      .getProductWithFixedVendor(productId, vendorId)
      .pipe(take(1), untilDestroyed(this))
      .subscribe((product) => {
        if (!product) this.productId.set(undefined);

        this.previewData.set(product);
        this.previewDataLoading.set(false);
      });
  }
}
