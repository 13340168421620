<ng-scrollbar
  class="cc-table--scrollbar"
  (scrolled)="loadNextContacts()"
  [infiniteScrollDistance]="4"
  [infiniteScrollThrottle]="0"
  [infiniteScrollUpDistance]="4"
  [scrollWindow]="false"
  orientation="vertical"
  cdkScrollable
  infiniteScroll
>
  <div class="cc-table--scrollbar-content">
    <cc-table
      (searchChange)="handleSearchChange($event)"
      [ccSearchable]="true"
      [ccSearchPlaceholder]="'assigneePicker.dialog.contacts.searchPlaceholder' | translate"
      [ccEmptyRowTemplate]="emptyRowTemplate"
      class="cc-table"
      ccPadding="small"
    >
      <ng-container slot="cc-table-title">
        @if (loadingStatus() === 'loading' && !loadedContacts()) {
          <span class="cc-ellipsis-animation">
            {{ 'common.strings.loading' | translate }}
          </span>
        } @else {
          @if (contactsTotal() === 1) {
            {{ 'assigneePicker.dialog.contacts.contactsCountSingular' | translate }}
          } @else {
            {{ 'assigneePicker.dialog.contacts.contactsCountPlural' | translate: { count: contactsTotal() } }}
          }
        }
      </ng-container>

      <ng-container slot="cc-table">
        <thead>
          <tr ccTr>
            <th
              ccTh
              ccWidth="50%"
              [ccSortable]="true"
              [ccSortDirection]="nameSortingOrder$ | async"
              (ccSortChange)="changeSort('name', $event)"
            >
              <app-checkbox
                [checked]="checkboxChecked()"
                [intermediate]="checkboxIndeterminate()"
                (change)="handleAllContactsCheckboxChange($event)"
              />

              {{ 'assigneePicker.dialog.contacts.columns.contact' | translate }}
            </th>

            <th
              ccTh
              ccWidth="50%"
              [ccSortable]="true"
              [ccSortDirection]="emailSortingOrder$ | async"
              (ccSortChange)="changeSort('email', $event)"
            >
              {{ 'assigneePicker.dialog.contacts.columns.email' | translate }}
            </th>
          </tr>
        </thead>

        <tbody>
          @for (contact of contacts(); track contact.id) {
            <tr ccTr>
              <cc-assignee-picker-dialog-contacts-table-row
                [contact]="contact"
                [selected]="contactIds().includes(contact.id)"
                (selectedChange)="handleContactSelect(contact.id, $event)"
              />
            </tr>
          }

          <ng-template #emptyRowTemplate>
            @if (loadingStatus() === 'loading') {
              {{ 'assigneePicker.dialog.contacts.loading' | translate }}
            } @else {
              {{ 'assigneePicker.dialog.contacts.noContacts' | translate }}
            }
          </ng-template>
        </tbody>
      </ng-container>
    </cc-table>
  </div>
</ng-scrollbar>
