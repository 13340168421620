<ng-scrollbar
  class="cc-table--scrollbar"
  orientation="vertical"
  cdkScrollable
>
  <div class="cc-table--scrollbar-content">
    <cc-table
      (searchChange)="handleSearchChange($event)"
      [ccSearchable]="true"
      [ccSearchPlaceholder]="'assigneePicker.dialog.departments.searchPlaceholder' | translate"
      [ccEmptyRowTemplate]="emptyRowTemplate"
      class="cc-table"
      ccPadding="small"
    >
      <ng-container slot="cc-table-title">
        @if (loadingStatus() === 'loading' && !loadedDepartments()) {
          <span class="cc-ellipsis-animation">
            {{ 'common.strings.loading' | translate }}
          </span>
        } @else {
          @if (departmentsTotal() === 1) {
            {{ 'assigneePicker.dialog.departments.departmentsCountSingular' | translate }}
          } @else {
            {{ 'assigneePicker.dialog.departments.departmentsCountPlural' | translate: { count: departmentsTotal() } }}
          }
        }
      </ng-container>

      <ng-container slot="cc-table">
        <thead>
          <tr ccTr>
            <th
              ccTh
              [ccSortable]="true"
              [ccSortDirection]="titleSortingOrder$ | async"
              (ccSortChange)="changeSort('title', $event)"
            >
              <app-checkbox
                [checked]="checkboxChecked()"
                [intermediate]="checkboxIndeterminate()"
                (change)="handleAllDepartmentsCheckboxChange($event)"
              />

              {{ 'assigneePicker.dialog.departments.columns.department' | translate }}
            </th>
          </tr>
        </thead>

        <tbody>
          @for (department of departments(); track department.id) {
            <tr ccTr>
              <cc-assignee-picker-dialog-departments-table-row
                [department]="department"
                [selected]="departmentIds().includes(department.id)"
                (selectedChange)="handleDepartmentSelect(department.id, $event)"
              />
            </tr>
          }

          <ng-template #emptyRowTemplate>
            @if (loadingStatus() === 'loading') {
              {{ 'assigneePicker.dialog.departments.loading' | translate }}
            } @else {
              {{ 'assigneePicker.dialog.departments.noDepartments' | translate }}
            }
          </ng-template>
        </tbody>
      </ng-container>
    </cc-table>
  </div>
</ng-scrollbar>
