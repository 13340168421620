import { TooltipAlignment } from '../../tooltip/tooltip';
import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';
import type { AssociatedCompany } from '@clover/network-v2/network/accounts/state/accounts.entities';
import { ButtonComponent } from '../../../buttons/button/button.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TranslateModule } from '@ngx-translate/core';
import { TabSelectorComponent, type TabSelectorItem } from '../../../navigation/tab-selector/tab-selector.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AutoAnimateDirective } from '@clover/core/directives/auto-animate.directive';
import { CompanyDrawerDetailsComponent } from './company-drawer-details/company-drawer-details.component';

type CompanyDrawerTab = 'details' | 'activity';

const tabs: TabSelectorItem[] = [{ id: 'details', labelTranslationKey: 'companyDrawer.tabs.details', disabled: false }];

@Component({
  selector: 'cc-company-drawer',
  standalone: true,
  imports: [
    ButtonComponent,
    TooltipDirective,
    TranslateModule,
    TabSelectorComponent,
    NgScrollbarModule,
    AutoAnimateDirective,
    CompanyDrawerDetailsComponent,
  ],
  templateUrl: './company-drawer.component.html',
  styleUrl: './company-drawer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyPickerDialogDrawerComponent {
  company = input.required<AssociatedCompany>();
  showCloseButton = input<boolean>(true);

  drawerClose = output<void>();

  protected activeTab = signal<CompanyDrawerTab>('details');

  protected readonly tabs = tabs;
  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly TooltipAlignment = TooltipAlignment;

  selectTab(tabId: string): void {
    this.activeTab.set(tabId as CompanyDrawerTab);
  }
}
