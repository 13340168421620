<div class="company-drawer-details">
  @if (companyType(); as companyType) {
    <div class="company-drawer-details__section">
      <div class="company-drawer-details__section-title">
        {{ 'companyDrawer.detailsTab.companyType' | translate }}
      </div>

      <div class="company-drawer-details__section-content">
        {{ companyType }}
      </div>
    </div>
  }

  @if (industries(); as industries) {
    <div class="company-drawer-details__section">
      <div class="company-drawer-details__section-title">
        {{ 'companyDrawer.detailsTab.industries' | translate }}
      </div>

      <div class="company-drawer-details__section-content">
        <div class="industries-container">
          @for (industry of industries; track industry.key) {
            <cc-chip size="small">
              {{ industry.title }}
            </cc-chip>
          }
        </div>
      </div>
    </div>
  }

  @if (numberOfEmployees(); as numberOfEmployees) {
    <div class="company-drawer-details__section">
      <div class="company-drawer-details__section-title">
        {{ 'companyDrawer.detailsTab.numberOfEmployees' | translate }}
      </div>

      <div class="company-drawer-details__section-content">
        {{ numberOfEmployees }}
      </div>
    </div>
  }

  @if (annualRevenue(); as annualRevenue) {
    <div class="company-drawer-details__section">
      <div class="company-drawer-details__section-title">
        {{ 'companyDrawer.detailsTab.annualRevenue' | translate }}
      </div>

      <div class="company-drawer-details__section-content">
        {{ annualRevenue }}
      </div>
    </div>
  }

  @if (description(); as description) {
    <div class="company-drawer-details__section">
      <div class="company-drawer-details__section-title">
        {{ 'companyDrawer.detailsTab.description' | translate }}
      </div>

      <div class="company-drawer-details__section-content">
        {{ description }}
      </div>
    </div>
  }

  @if (address(); as address) {
    <div class="company-drawer-details__section">
      <div class="company-drawer-details__section-title">
        {{ 'companyDrawer.detailsTab.address' | translate }}
      </div>

      <div class="company-drawer-details__section-content">
        {{ address }}
      </div>
    </div>
  }

  @if (website(); as website) {
    <div class="company-drawer-details__section">
      <div class="company-drawer-details__section-title">
        {{ 'companyDrawer.detailsTab.website' | translate }}
      </div>

      <div class="company-drawer-details__section-content">
        <a
          [href]="website"
          target="_blank"
        >
          {{ website }}
        </a>
      </div>
    </div>
  }
</div>
