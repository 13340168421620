<cc-table ccPadding="medium">
  <ng-container slot="cc-table">
    <thead>
      <tr ccTr>
        <th ccTh>
          {{ 'productPicker.preview.columns.product' | translate }}
        </th>

        <th
          ccTh
          ccWidth="200px"
        >
          {{ 'productPicker.preview.columns.itemNumber' | translate }}
        </th>

        @if (supplierColumn()) {
          <th
            ccTh
            ccWidth="200px"
          >
            {{ 'productPicker.preview.columns.supplier' | translate }}
          </th>
        }
      </tr>
    </thead>

    <tbody>
      <tr ccTr>
        <td ccTd>
          <div class="product-details-column">
            <img
              class="product-details-column__image"
              [class.product-details-column__image--small]="!!supplierColumn()"
              [src]="productImage() || 'assets/svg/common/new-company-placeholder.svg'"
              [alt]="productImageAlt()"
            />

            <div class="product-details-column__details">
              <div class="product-details-column__details-name">
                {{ product().name }}
              </div>

              <div class="product-details-column__details-gtin">
                <div class="product-details-column__details-gtin-title">GTIN / UPC</div>
                <div class="product-details-column__details-gtin-value">
                  {{ product().gtin || 'N/A' }}
                </div>
              </div>

              @if (!supplierColumn()) {
                <div class="product-details-column__details-vendor">
                  <img
                    class="product-details-column__details-vendor-logo"
                    [src]="product().vendor.companyLogoUrl || 'assets/svg/common/new-company-placeholder.svg'"
                    [alt]="product().vendor.companyName"
                  />
                  <div class="product-details-column__details-vendor-name">
                    {{ product().vendor.companyName }}
                  </div>
                </div>
              }
            </div>
          </div>
        </td>

        <td
          ccTd
          ccVerticalAlignment="center"
        >
          {{ product().itemNumber }}
        </td>

        @if (supplierColumn(); as supplierColumnRef) {
          <td
            ccTd
            ccVerticalAlignment="center"
          >
            <ng-container [ngTemplateOutlet]="supplierColumnRef" />
          </td>
        }
      </tr>
    </tbody>
  </ng-container>
</cc-table>
