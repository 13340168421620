import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { DropdownTriggerDirective } from '@design/overlays/dropdown/dropdown-trigger.directive';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';

import { TaskAudienceDepartment } from '../../../../app/conversations-v4/tasks/task-assignment/task-assignment.model';
import { ButtonComponent } from '../../../buttons/button/button.component';
import { DropdownActionComponent } from '../../../overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownComponent } from '../../../overlays/dropdown/dropdown.component';
import { TableComponent } from '../../../table/table.component';
import { AssigneePickerDialogDepartmentsTableRowComponent } from '../assignee-picker-dialog/departments-table/departments-table-row/departments-table-row.component';

@Component({
  selector: 'cc-task-assignment-assignee-picker-dialog-departments-preview-table',
  standalone: true,
  imports: [
    TableComponent,
    AssigneePickerDialogDepartmentsTableRowComponent,
    TrComponent,
    ThComponent,
    TranslateModule,
    ButtonComponent,
    DropdownComponent,
    DropdownActionComponent,
    DropdownTriggerDirective,
    TooltipDirective,
  ],
  templateUrl: './departments-preview-table.component.html',
  styleUrl: './departments-preview-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskAssignmentAssigneePickerDialogDepartmentsPreviewTableComponent {
  departments = input<TaskAudienceDepartment[]>();

  departmentRemove = output<string>();

  protected searchQuery = signal<string>('');
  protected readonly filteredDepartments = computed(() => {
    const query = this.searchQuery();
    if (!query) return this.departments();

    return this.departments().filter((department) => {
      const { title } = department;

      return title.toLowerCase().includes(query.toLowerCase());
    });
  });

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly TooltipAlignment = TooltipAlignment;
}
