import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { UserAvatarComponent } from '@design/misc/user-avatar/user-logo.component';
import { DropdownTriggerDirective } from '@design/overlays/dropdown/dropdown-trigger.directive';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TdComponent } from '@design/table/td/td.component';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';

import { type TaskAudienceContact } from '../../../../app/conversations-v4/tasks/task-assignment/task-assignment.model';
import { ButtonComponent } from '../../../buttons/button/button.component';
import { DropdownActionComponent } from '../../../overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownComponent } from '../../../overlays/dropdown/dropdown.component';
import { TableComponent } from '../../../table/table.component';

@Component({
  selector: 'cc-task-assignment-assignee-picker-dialog-contacts-preview-table',
  standalone: true,
  imports: [
    TableComponent,
    TrComponent,
    ThComponent,
    TdComponent,
    TranslateModule,
    ButtonComponent,
    DropdownComponent,
    DropdownActionComponent,
    DropdownTriggerDirective,
    TooltipDirective,
    UserAvatarComponent,
  ],
  templateUrl: './contacts-preview-table.component.html',
  styleUrl: './contacts-preview-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskAssignmentAssigneePickerDialogContactsPreviewTableComponent {
  contacts = input.required<TaskAudienceContact[]>();
  contactRemove = output<number>();

  protected searchQuery = signal<string>('');
  protected readonly filteredContacts = computed(() => {
    const query = this.searchQuery();
    if (!query) return this.contacts();

    return this.contacts().filter((contact) => {
      const { firstName, lastName } = contact;
      const fullName = `${firstName} ${lastName}`;

      return (
        fullName.toLowerCase().includes(query.toLowerCase()) ||
        firstName.toLowerCase().includes(query.toLowerCase()) ||
        lastName.toLowerCase().includes(query.toLowerCase())
      );
    });
  });

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly TooltipAlignment = TooltipAlignment;
}
