import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbar } from 'ngx-scrollbar';

import { AutoAnimateDirective } from '@clover/core/directives/auto-animate.directive';
import type { WorkflowDetails } from '@clover/workflows-v2/state/workflows-state.model';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { ButtonComponent } from '@design/buttons/button/button.component';
import {
  TabSelectorComponent,
  type TabSelectorItem,
} from '@design/navigation/tab-selector/tab-selector.component';
import { WorkflowDrawerActivityComponent } from './workflow-drawer-activity/workflow-drawer-activity.component';
import { WorkflowDrawerDetailsComponent } from './workflow-drawer-details/workflow-drawer-details.component';

type WorkflowDrawerTab = 'details' | 'activity';

const tabs: TabSelectorItem[] = [
  { id: 'details', labelTranslationKey: 'workflowDrawer.tabs.details', disabled: false },
  { id: 'activity', labelTranslationKey: 'workflowDrawer.tabs.activity', disabled: false },
];

@Component({
  selector: 'cc-workflow-drawer',
  standalone: true,
  imports: [
    ButtonComponent,
    TooltipDirective,
    TranslateModule,
    NgScrollbar,
    TabSelectorComponent,
    WorkflowDrawerDetailsComponent,
    WorkflowDrawerActivityComponent,
    AutoAnimateDirective,
  ],
  templateUrl: './workflow-drawer.component.html',
  styleUrl: './workflow-drawer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowDrawerComponent {
  workflow = input.required<WorkflowDetails>();
  showCloseButton = input<boolean>(true);

  drawerClose = output<void>();

  protected activeTab = signal<WorkflowDrawerTab>('details');

  protected readonly tabs = tabs;
  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly TooltipAlignment = TooltipAlignment;

  selectTab(tabId: string): void {
    this.activeTab.set(tabId as WorkflowDrawerTab);
  }
}
