<cc-table
  ccPadding="small"
  [ccSearchable]="true"
  [ccSearchPlaceholder]="'assigneePicker.preview.departments.searchPlaceholder' | translate"
  [ccEmptyRowTemplate]="noDepartments"
  [ccSearchDebounceTime]="0"
  (searchChange)="searchQuery.set($event)"
>
  <ng-container slot="cc-table">
    <thead>
      <tr ccTr>
        <th ccTh>
          {{ 'assigneePicker.preview.departments.columns.department' | translate }}
        </th>

        <th
          ccTh
          ccWidth="48px"
        ></th>
      </tr>
    </thead>

    <tbody>
      @for (department of filteredDepartments(); track department.id) {
        <tr ccTr>
          <cc-assignee-picker-dialog-departments-table-row
            [department]="department"
            [selectable]="false"
          />

          <td ccTd>
            <app-button
              [ccTooltip]="'common.buttons.moreActions' | translate"
              [tooltipAlignment]="TooltipAlignment.End"
              [ccDropdownTrigger]="dropdown"
              [ccDropdownPositions]="{
                offsetY: 6,
                positions: ['bottom-end', 'top-end'],
              }"
              [type]="ButtonType.Ghost"
              [size]="ButtonSize.Small"
            >
              <i
                class="icon-more"
                slot="ghost-icon"
              ></i>
            </app-button>

            <ng-template #dropdown>
              <cc-dropdown width="160px">
                <cc-dropdown-action (click)="departmentRemove.emit(department.id)">
                  <ng-container slot="title">
                    {{ 'assigneePicker.preview.departments.remove' | translate }}
                  </ng-container>
                </cc-dropdown-action>
              </cc-dropdown>
            </ng-template>
          </td>
        </tr>
      }
    </tbody>
  </ng-container>
</cc-table>

<ng-template #noDepartments>
  {{ 'assigneePicker.preview.departments.noDepartments' | translate }}
</ng-template>
