import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Renderer2 } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { take } from 'rxjs';

import { ConnectionStatus } from '@clover/core/models/company';
import type { AssociatedCompany } from '@clover/network-v2/network/accounts/state/accounts.entities';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import {
  CompanyPickerDialogComponent,
  type CompanyPickerDialogData,
  type CompanyPickerDialogResult,
} from '@design/overlays/company-picker-dialog/company-picker-dialog.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import type { Product } from './temp-products.service';
import { ButtonComponent } from '../../../../../../stories/buttons/button/button.component';
import { TextboxComponent } from '../../../../../../stories/forms/textbox/textbox.component';
import { TaskAssignmentAssigneePickerDialogContactsPreviewTableComponent } from '../../../../../../stories/misc/product-picker/product-picker-preview/product-picker-preview.component';

export type VendorAssociationDialogData = Product;
export type VendorAssociationDialogResult = VendorAssociationResult;

export interface VendorAssociationResult {
  productId: number;
  vendorId: number;
}

@UntilDestroy()
@Component({
  selector: 'cc-vendor-association-dialog',
  standalone: true,
  imports: [
    ButtonComponent,
    NgScrollbar,
    TooltipDirective,
    TranslateModule,
    TaskAssignmentAssigneePickerDialogContactsPreviewTableComponent,
    TextboxComponent,
  ],
  templateUrl: './vendor-association-dialog.component.html',
  styleUrl: './vendor-association-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorAssociationDialogComponent {
  protected readonly vendorFormControl = new FormControl<AssociatedCompany | undefined>(undefined, Validators.required);

  protected readonly product: VendorAssociationDialogData = inject(DIALOG_DATA);
  protected readonly dialogRef: DialogRef<VendorAssociationDialogResult | undefined> = inject(
    DialogRef<VendorAssociationDialogResult | undefined>,
  );

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;

  private readonly dialog = inject(Dialog);
  private readonly renderer = inject(Renderer2);
  private readonly cdr = inject(ChangeDetectorRef);

  fixSupplier(): void {
    const dialog = this.dialog.open<CompanyPickerDialogResult, CompanyPickerDialogData>(CompanyPickerDialogComponent, {
      data: {
        connectionStatuses: [ConnectionStatus.Connected, ConnectionStatus.Invited],
      },
    });
    this.handleChildDialogOpened();

    dialog.closed.pipe(untilDestroyed(this), take(1)).subscribe((supplier) => {
      this.handleChildDialogClosed();

      if (!supplier) return;

      this.vendorFormControl.setValue(supplier);
      this.cdr.detectChanges();
    });
  }

  save(): void {
    if (this.vendorFormControl.invalid) return;

    this.dialogRef.close({
      productId: this.product.id,
      vendorId: this.vendorFormControl.value.id,
    });
  }

  private handleChildDialogOpened(): void {
    this.dialogRef.disableClose = true;

    const overlayRef = this.dialogRef.overlayRef;
    const { hostElement, backdropElement } = overlayRef;
    this.renderer.addClass(hostElement, 'cc-overlay-hidden');
    this.renderer.addClass(backdropElement, 'cc-overlay-hidden');
  }

  private handleChildDialogClosed(): void {
    this.dialogRef.disableClose = false;

    const overlayRef = this.dialogRef.overlayRef;
    const { hostElement, backdropElement } = overlayRef;
    this.renderer.removeClass(hostElement, 'cc-overlay-hidden');
    this.renderer.removeClass(backdropElement, 'cc-overlay-hidden');
  }
}
