<div class="route-picker-dialog">
  <div class="route-picker-dialog__header">
    <div class="route-picker-dialog__header-details">
      <div class="route-picker-dialog__header-details-title">{{ dialogData.title }}</div>
      <div class="route-picker-dialog__header-details-description">{{ dialogData.description }}</div>
    </div>

    <div class="route-picker-dialog__header-actions">
      <app-button
        [ccTooltip]="'common.buttons.close' | translate"
        [type]="ButtonType.Ghost"
        [size]="ButtonSize.Big"
        (click)="dialogRef.close()"
      >
        <i
          class="icon-close"
          slot="ghost-icon"
        ></i>
      </app-button>
    </div>
  </div>

  <div class="route-picker-dialog__content">
    <div class="route-picker-dialog__content-options">
      @for (option of dialogData.options; track option.id) {
        <button
          (click)="dialogRef.close(option.id)"
          class="route-picker-dialog__content-options-item"
        >
          <i
            class="route-picker-dialog__content-options-item-icon"
            [class]="option.icon"
          >
          </i>

          <div class="route-picker-dialog__content-options-item-details">
            <div class="route-picker-dialog__content-options-item-details-title">
              {{ option.title }}
            </div>

            <div class="route-picker-dialog__content-options-item-details-description">
              {{ option.description }}
            </div>
          </div>
        </button>
      }
    </div>
  </div>
</div>
