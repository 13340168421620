import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { generateGenericAvatar } from '@clover/core/helpers/generateGenericAvatar';
import { DateFormatPipe } from '@clover/core/pipes/date-format.pipe';
import { WorkflowAccessType, type WorkflowDetails } from '@clover/workflows-v2/state/workflows-state.model';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import { WorkflowStatusChipComponent } from '../../../../../../../app/workflows-v2/workflows-list/workflows-table/workflow-table-row/workflow-status-chip/workflow-status-chip.component';

@Component({
  selector: 'cc-workflow-drawer-details',
  standalone: true,
  imports: [DateFormatPipe, WorkflowStatusChipComponent, TranslateModule, TooltipDirective, TranslateModule],
  templateUrl: './workflow-drawer-details.component.html',
  styleUrl: './workflow-drawer-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowDrawerDetailsComponent {
  workflow = input.required<WorkflowDetails>();

  private readonly translate = inject(TranslateService);

  usersWithAccess = computed(() => {
    const { access } = this.workflow();

    if (access.type === WorkflowAccessType.All)
      return this.translate.instant('workflowDrawer.detailsTab.allTeamsHaveAccess');
    if (access.type === WorkflowAccessType.Teams && access.teams.length > 0)
      return access.teams.map((team) => team.name).join(', ');

    return this.translate.instant('workflowDrawer.detailsTab.noTeamsHaveAccess');
  });

  ownerAvatar = computed(() => {
    const { createdBy: owner } = this.workflow();

    const { avatarUrl } = owner;
    if (avatarUrl) return avatarUrl;

    const { firstName, lastName } = owner;
    return generateGenericAvatar(firstName || '–', lastName || '', 48);
  });

  ownerFullName = computed(() => {
    const { createdBy: owner } = this.workflow();
    return `${owner.firstName} ${owner.lastName}`;
  });
}
