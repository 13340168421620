import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, type OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbar } from 'ngx-scrollbar';

import type { TaskAudience } from '@clover/conversations-v4/tasks/task-assignment/task-assignment.model';
import { AutoAnimateDirective } from '@clover/core/directives/auto-animate.directive';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TabSelectorComponent, type TabSelectorItem } from '@design/navigation/tab-selector/tab-selector.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import { AssigneePickerDialogContactsTableComponent } from './contacts-table/contacts-table.component';
import { AssigneePickerDialogDepartmentsTableComponent } from './departments-table/departments-table.component';
import { ButtonComponent } from '../../../buttons/button/button.component';

export interface AssigneePickerDialogData {
  companyId: number;
  audience: TaskAudience;
}

export type AssigneePickerDialogResult = TaskAudience;

@Component({
  standalone: true,
  imports: [
    ButtonComponent,
    NgScrollbar,
    TabSelectorComponent,
    TooltipDirective,
    TranslateModule,
    AutoAnimateDirective,
    AssigneePickerDialogContactsTableComponent,
    AssigneePickerDialogDepartmentsTableComponent,
  ],
  templateUrl: './assignee-picker-dialog.component.html',
  styleUrl: './assignee-picker-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssigneePickerDialogComponent implements OnInit {
  protected activeTabId: 'contacts' | 'departments' = 'contacts';

  protected readonly form = new FormGroup({
    contactIds: new FormControl<number[]>([], [Validators.required]),
    departmentIds: new FormControl<string[]>([], [Validators.required]),
  });

  protected readonly dialogData: AssigneePickerDialogData = inject(DIALOG_DATA);
  protected readonly dialogRef: DialogRef<AssigneePickerDialogResult | undefined> = inject(
    DialogRef<AssigneePickerDialogResult | undefined>,
  );

  protected readonly tabs: TabSelectorItem[] = [
    { id: 'contacts', labelTranslationKey: 'assigneePicker.dialog.tabs.contacts', disabled: false },
    { id: 'departments', labelTranslationKey: 'assigneePicker.dialog.tabs.departments', disabled: false },
  ];

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;

  get contactIds(): number[] {
    return this.form.get('contactIds').value;
  }

  get departmentIds(): string[] {
    return this.form.get('departmentIds').value;
  }

  ngOnInit(): void {
    const contactIds = this.dialogData.audience.contactIds || [];
    const departmentIds = this.dialogData.audience.departmentIds || [];

    if (contactIds.length > departmentIds.length) {
      this.handleTabChange('contacts');
    } else if (contactIds.length < departmentIds.length) {
      this.handleTabChange('departments');
    } else {
      this.handleTabChange('contacts');
    }

    this.form.get('contactIds').setValue(contactIds);
    this.form.get('departmentIds').setValue(departmentIds);
  }

  handleTabChange(tabId: string): void {
    this.activeTabId = tabId as 'contacts' | 'departments';
    this.updateFormControlsEnabledState();
  }

  handleContactsChange(contacts: number[]): void {
    this.form.get('contactIds').setValue(contacts);
  }

  handleDepartmentsChange(departments: string[]): void {
    this.form.get('departmentIds').setValue(departments);
  }

  save(): void {
    if (this.form.invalid) return;

    const result: AssigneePickerDialogResult = {
      contactIds: this.activeTabId === 'contacts' ? this.form.get('contactIds').value : [],
      departmentIds: this.activeTabId === 'departments' ? this.form.get('departmentIds').value : [],
    };

    this.dialogRef.close(result);
  }

  private updateFormControlsEnabledState(): void {
    switch (this.activeTabId) {
      case 'contacts':
        this.form.get('contactIds').enable();
        this.form.get('departmentIds').disable();
        break;
      case 'departments':
        this.form.get('contactIds').disable();
        this.form.get('departmentIds').enable();
        break;
    }
  }
}
