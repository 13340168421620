import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbar } from 'ngx-scrollbar';

import type { WorkflowType } from '@clover/workflows-v2/state/workflows-state.model';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import { WorkflowPickerDialogFoldersComponent } from './workflow-picker-dialog-folders/workflow-picker-dialog-folders.component';
import { WorkflowsPickerDialogListComponent } from './workflows-picker-dialog-list/workflows-picker-dialog-list.component';

export interface WorkflowLibraryDialogData {
  workflowId: number;
  preselectedFolderId?: number;
  allowedWorkflowType?: WorkflowType | undefined;
}

export type WorkflowLibraryDialogResult = number;

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    ButtonComponent,
    TranslateModule,
    TooltipDirective,
    NgScrollbar,
    WorkflowPickerDialogFoldersComponent,
    WorkflowsPickerDialogListComponent,
  ],
  templateUrl: './workflow-picker-dialog.component.html',
  styleUrl: './workflow-picker-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowPickerDialogComponent {
  protected readonly dialogData: WorkflowLibraryDialogData = inject(DIALOG_DATA);
  protected readonly dialogRef: DialogRef<WorkflowLibraryDialogResult | undefined> = inject(
    DialogRef<WorkflowLibraryDialogResult | undefined>,
  );

  protected readonly selectedFolderId = signal<number | undefined>(this.dialogData.preselectedFolderId);
  protected readonly selectedWorkflowId = signal<number | undefined>(this.dialogData.workflowId);

  protected readonly foldersPopulated = signal<boolean>(true);

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;

  selectWorkflow(): void {
    this.dialogRef.close(this.selectedWorkflowId());
  }
}
