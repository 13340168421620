import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, input, model, output, signal, type OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { map, switchMap, take, tap } from 'rxjs';

import { AutoAnimateDirective } from '@clover/core/directives/auto-animate.directive';
import { WorkflowType, type WorkflowDetails } from '@clover/workflows-v2/state/workflows-state.model';
import { WorkflowsService } from '@clover/workflows-v2/state/workflows.service';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';

import { WorkflowFolder } from './workflow-picker-dialog/workflow-library.model';
import { WorkflowLibraryService } from './workflow-picker-dialog/workflow-library.service';
import {
  WorkflowPickerDialogComponent,
  type WorkflowLibraryDialogData,
  type WorkflowLibraryDialogResult,
} from './workflow-picker-dialog/workflow-picker-dialog.component';
import { WorkflowPickerPreviewComponent } from './workflow-picker-preview/workflow-picker-preview.component';
import { ButtonComponent } from '../../buttons/button/button.component';

type WorkflowPickerPreviewData = WorkflowDetails;

@UntilDestroy()
@Component({
  selector: 'cc-task-assignment-workflow-picker',
  standalone: true,
  imports: [ButtonComponent, WorkflowPickerPreviewComponent, AutoAnimateDirective, TranslateModule],
  templateUrl: './workflow-picker.component.html',
  styleUrl: './workflow-picker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskAssignmentWorkflowPickerComponent implements OnInit {
  workflowId = model.required<number | undefined>();
  allowedWorkflowType = input<WorkflowType | undefined>(undefined);

  childDialogOpened = output<void>();
  childDialogClosed = output<void>();

  folderId = signal<number | undefined>(undefined);

  protected readonly previewData = signal<WorkflowPickerPreviewData | undefined>(undefined);
  protected readonly previewDataLoading = signal<boolean>(false);
  protected readonly resolvedWorkflowFolder = signal<WorkflowFolder | undefined>(undefined);

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;

  private readonly dialog = inject(Dialog);
  private readonly workflowsService = inject(WorkflowsService);
  private readonly workflowLibraryService = inject(WorkflowLibraryService);

  ngOnInit(): void {
    this.loadPreviewData();
  }

  openSelectDialog(): void {
    const dialog = this.dialog.open<WorkflowLibraryDialogResult, WorkflowLibraryDialogData>(
      WorkflowPickerDialogComponent,
      {
        data: {
          workflowId: this.workflowId(),
          preselectedFolderId: this.folderId(),
          allowedWorkflowType: this.allowedWorkflowType(),
        },
      },
    );
    this.childDialogOpened.emit();
    dialog.closed
      .pipe(
        take(1),
        tap((workflowId: WorkflowLibraryDialogResult | undefined) => {
          this.childDialogClosed.emit();
          if (!workflowId) return;
          this.workflowId.set(workflowId);
          this.loadPreviewData();
        }),
      )
      .subscribe();
  }

  private loadPreviewData(): void {
    const workflowId = this.workflowId();
    this.previewData.set(undefined);
    this.resolvedWorkflowFolder.set(undefined);

    if (!workflowId) return;

    this.previewDataLoading.set(true);

    this.workflowsService
      .getWorkflowDetails(workflowId)
      .pipe(
        take(1),
        untilDestroyed(this),
        tap((workflow) => this.folderId.set(workflow.folderId)),
        switchMap((workflow) => {
          return this.workflowLibraryService.getFolder(workflow.folderId).pipe(
            tap((folder) => {
              this.resolvedWorkflowFolder.set(folder);
            }),
            map(() => workflow),
          );
        }),
      )
      .subscribe((workflow) => {
        this.previewData.set(workflow);
        this.previewDataLoading.set(false);
      });
  }
}
