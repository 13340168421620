<td ccTd>
  @if (selectable()) {
    <app-checkbox
      [checked]="selected()"
      (change)="handleSelectChange($event)"
    />
  }

  {{ department().title }}
</td>
