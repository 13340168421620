import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, TemplateRef } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import type { Product } from '@clover/conversations-v4/tasks/task-assignment/product-dialog/vendor-association-dialog/temp-products.service';
import { TdComponent } from '@design/table/td/td.component';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';

import { TableComponent } from '../../../table/table.component';

@Component({
  selector: 'cc-task-assignment-product-picker-dialog-preview',
  standalone: true,
  imports: [TableComponent, TrComponent, ThComponent, TdComponent, TranslateModule, NgTemplateOutlet],
  templateUrl: './product-picker-preview.component.html',
  styleUrl: './product-picker-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskAssignmentAssigneePickerDialogContactsPreviewTableComponent {
  product = input<Product>();
  supplierColumn = input<TemplateRef<unknown> | undefined>();

  protected readonly productImage = computed(() => (this.product()?.images || [])[0]?.url);
  protected readonly productImageAlt = computed(() => (this.product()?.images || [])[0]?.description || '');
}
