import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, Renderer2, type OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbar } from 'ngx-scrollbar';

import { WorkflowType } from '@clover/workflows-v2/state/workflows-state.model';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import {
  createAudienceRequiredValidator,
  TaskAssignmentAssigneePickerComponent,
} from '@design/misc/assignee-picker/assignee-picker.component';
import { TaskAssignmentWorkflowPickerComponent } from '@design/misc/workflow-picker/workflow-picker.component';

import { TaskAssignmentAssigneePickerDialogContactsPreviewTableComponent } from '../../../../../stories/misc/product-picker/product-picker-preview/product-picker-preview.component';
import { TaskAssignmentProductPickerComponent } from '../../../../../stories/misc/product-picker/product-picker.component';
import type { TaskAudience } from '../task-assignment.model';
import type { ProductTaskAssignmentConfig } from '../task-assignment.service';

export interface ProductTaskAssignmentDialogData extends ProductTaskAssignmentConfig {
  productId: number; // Overriding optional productId from TaskAssignmentFlowConfig
  vendorId: number; // Overriding optional vendorId from TaskAssignmentFlowConfig
}

export interface ProductTaskAssignmentDialogResult {
  productId: number;
  vendorId: number;
  workflowId: number;
  audience: TaskAudience;
}

@Component({
  standalone: true,
  imports: [
    ButtonComponent,
    NgScrollbar,
    TaskAssignmentWorkflowPickerComponent,
    TaskAssignmentAssigneePickerComponent,
    TranslateModule,
    TaskAssignmentAssigneePickerDialogContactsPreviewTableComponent,
    TaskAssignmentProductPickerComponent,
  ],
  templateUrl: './product-dialog.component.html',
  styleUrl: './product-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTaskAssignmentDialogComponent implements OnInit {
  protected readonly form = new FormGroup({
    workflowId: new FormControl<number>(null, Validators.required),
    audience: new FormControl<TaskAudience>(null, createAudienceRequiredValidator()),
  });

  protected readonly dialogData: ProductTaskAssignmentDialogData = inject(DIALOG_DATA);
  protected readonly dialogRef: DialogRef<ProductTaskAssignmentDialogResult | undefined> = inject(
    DialogRef<ProductTaskAssignmentDialogResult | undefined>,
  );

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly WorkflowType = WorkflowType;

  private readonly renderer = inject(Renderer2);

  get selectedWorkflowId(): number {
    return this.form.get('workflowId').value;
  }

  get selectedAudience(): TaskAudience {
    return this.form.get('audience').value;
  }

  ngOnInit(): void {
    this.form.get('workflowId').setValue(this.dialogData?.predefinedWorkflowId);
    this.form.get('audience').setValue({
      contactIds: [],
      departmentIds: [],
      ...(this.dialogData?.predefinedAudience || {}),
    });
  }

  assignTask(): void {
    if (this.form.invalid) return;

    this.dialogRef.close({
      productId: this.dialogData.productId,
      vendorId: this.dialogData.vendorId,
      workflowId: this.form.get('workflowId').value,
      audience: this.form.get('audience').value,
    });
  }

  handleWorkflowChange(workflowId: number): void {
    this.form.get('workflowId').setValue(workflowId);
  }

  handleAudienceChange(audience: TaskAudience): void {
    this.form.get('audience').setValue(audience);
  }

  handleChildDialogOpened(): void {
    this.dialogRef.disableClose = true;

    const overlayRef = this.dialogRef.overlayRef;
    const { hostElement, backdropElement } = overlayRef;
    this.renderer.addClass(hostElement, 'cc-overlay-hidden');
    this.renderer.addClass(backdropElement, 'cc-overlay-hidden');
  }

  handleChildDialogClosed(): void {
    this.dialogRef.disableClose = false;

    const overlayRef = this.dialogRef.overlayRef;
    const { hostElement, backdropElement } = overlayRef;
    this.renderer.removeClass(hostElement, 'cc-overlay-hidden');
    this.renderer.removeClass(backdropElement, 'cc-overlay-hidden');
  }
}
