import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { DateFormatDistancePipe } from '@clover/core/pipes/date-format.pipe';
import type { WorkflowDetails } from '@clover/workflows-v2/state/workflows-state.model';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import { ButtonComponent } from '../../../buttons/button/button.component';
import type { WorkflowFolder } from '../workflow-picker-dialog/workflow-library.model';

@Component({
  selector: 'cc-workflow-picker-preview',
  standalone: true,
  imports: [ButtonComponent, TranslateModule, TooltipDirective, DateFormatDistancePipe],
  templateUrl: './workflow-picker-preview.component.html',
  styleUrl: './workflow-picker-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowPickerPreviewComponent {
  workflow = input.required<WorkflowDetails>();
  folder = input.required<WorkflowFolder>();

  edit = output<void>();

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
}
