<div class="task-assignment-product-picker">
  <div class="task-assignment-product-picker__title">
    {{ 'productPicker.products' | translate }}
  </div>

  <div
    class="task-assignment-product-picker__selected-items"
    [ccAutoAnimate]
  >
    @if (previewData(); as product) {
      <cc-task-assignment-product-picker-dialog-preview [product]="product" />
    }
  </div>
</div>
