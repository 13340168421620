<div class="task-assignment-workflow-picker">
  <div class="task-assignment-workflow-picker__title">
    {{ 'workflowPicker.workflow' | translate }}
  </div>

  <div
    class="task-assignment-workflow-picker__selected-item"
    [ccAutoAnimate]
  >
    @if (previewData(); as workflow) {
      <cc-workflow-picker-preview
        class="task-assignment-workflow-picker__selected-item-preview"
        [workflow]="workflow"
        [folder]="resolvedWorkflowFolder()"
        (edit)="openSelectDialog()"
      />
    } @else {
      <app-button
        (click)="openSelectDialog()"
        [type]="ButtonType.Secondary"
        [size]="ButtonSize.Small"
        [disabled]="previewDataLoading()"
        class="task-assignment-workflow-picker__selected-item-add-button"
      >
        <i
          class="icon-add"
          slot="start-icon"
        >
        </i>

        {{ 'workflowPicker.selectWorkflow' | translate }}
      </app-button>
    }
  </div>
</div>
