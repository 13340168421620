<div class="workflows-picker-dialog-list">
  <div class="workflows-picker-dialog-list__table">
    <cc-workflows-picker-dialog-list-table
      [allowedWorkflowType]="allowedWorkflowType()"
      [workflowFolderId]="workflowFolderId()"
      [(selectedWorkflowId)]="selectedWorkflowId"
    />
  </div>

  @if (workflowDetails$ | async; as workflow) {
    <div @drawerAnimation>
      <cc-workflow-drawer
        class="workflows-picker-dialog-list__drawer"
        [workflow]="workflow"
        [showCloseButton]="false"
      >
        <app-button
          [type]="ButtonType.Primary"
          [size]="ButtonSize.Small"
          (click)="selectWorkflow.emit()"
        >
          {{ 'workflowPicker.selectWorkflow' | translate }}
        </app-button>
      </cc-workflow-drawer>
    </div>
  }
</div>
