import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Injector, input, model, output, type OnInit } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of, type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import type { WorkflowDetails, WorkflowType } from '@clover/workflows-v2/state/workflows-state.model';
import { WorkflowsService } from '@clover/workflows-v2/state/workflows.service';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';

import { WorkflowDrawerComponent } from './workflow-drawer/workflow-drawer.component';
import { WorkflowsPickerDialogListTableComponent } from './workflows-picker-dialog-list-table/workflows-picker-dialog-list-table.component';
import { drawerAnimation } from '@design/misc/drawer-animation';

@UntilDestroy()
@Component({
  selector: 'cc-workflows-picker-dialog-list',
  standalone: true,
  imports: [
    WorkflowsPickerDialogListTableComponent,
    WorkflowDrawerComponent,
    AsyncPipe,
    ButtonComponent,
    TranslateModule,
  ],
  templateUrl: './workflows-picker-dialog-list.component.html',
  styleUrl: './workflows-picker-dialog-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [drawerAnimation],
})
export class WorkflowsPickerDialogListComponent implements OnInit {
  selectedWorkflowId = model<number | undefined>(undefined);
  workflowFolderId = input<number | undefined>(undefined);
  allowedWorkflowType = input<WorkflowType | undefined>(undefined);

  selectWorkflow = output<void>();

  protected workflowDetails$: Observable<WorkflowDetails | undefined>;

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;

  private readonly workflowsService = inject(WorkflowsService);
  private readonly injector = inject(Injector);

  ngOnInit(): void {
    this.workflowDetails$ = this.loadWorkflowDetails();
  }

  loadWorkflowDetails(): Observable<WorkflowDetails | undefined> {
    return toObservable(this.selectedWorkflowId, { injector: this.injector }).pipe(
      switchMap((workflowId) => {
        return workflowId ? this.workflowsService.getWorkflowDetails(workflowId) : of(undefined);
      }),
    );
  }
}
