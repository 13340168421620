<div class="workflow-drawer">
  <div class="workflow-drawer__header">
    <div class="workflow-drawer__header-actions">
      @if (showCloseButton()) {
        <app-button
          (click)="drawerClose.emit()"
          [ccTooltip]="'common.buttons.close' | translate"
          [size]="ButtonSize.Small"
          [tooltipAlignment]="TooltipAlignment.Start"
          [type]="ButtonType.Ghost"
        >
          <i
            class="icon-escape"
            slot="ghost-icon"
          ></i>
        </app-button>
      }

      <div class="workflow-drawer__header-actions-title">
        {{ 'workflowDrawer.workflowInfo' | translate }}
      </div>
    </div>

    <div class="workflow-drawer__header-details">
      <div class="workflow-drawer__header-details-title">
        {{ workflow().name }}
      </div>
    </div>
  </div>

  <div class="workflow-drawer__tabs">
    <cc-tab-selector
      (selectedTabIdChange)="selectTab($event)"
      [selectedTabId]="activeTab()"
      [tabs]="tabs"
      class="workflow-drawer__tabs-select"
      [inlinePadding]="4"
    />

    <div class="workflow-drawer__tabs-content">
      <ng-scrollbar orientation="vertical">
        <div [ccAutoAnimate]>
          @switch (activeTab()) {
            @case ('details') {
              <cc-workflow-drawer-details [workflow]="workflow()" />
            }
            @case ('activity') {
              <cc-workflow-drawer-activity />
            }
          }
        </div>
      </ng-scrollbar>
    </div>
  </div>

  <div class="workflow-drawer__custom-actions">
    <ng-content></ng-content>
  </div>
</div>
