<div class="task-assignment-assignee-picker">
  <div class="task-assignment-assignee-picker__title">
    {{ 'assigneePicker.assignees' | translate }}
  </div>

  <div
    class="task-assignment-assignee-picker__selected-items"
    [ccAutoAnimate]
  >
    @if (previewData().contacts; as contacts) {
      @if (contacts.length > 0) {
        <cc-task-assignment-assignee-picker-dialog-contacts-preview-table
          [contacts]="contacts"
          (contactRemove)="removeContact($event)"
        />
      }
    }

    @if (previewData().departments; as departments) {
      @if (departments.length > 0) {
        <cc-task-assignment-assignee-picker-dialog-departments-preview-table
          [departments]="departments"
          (departmentRemove)="removeDepartment($event)"
        />
      }
    }
  </div>

  <app-button
    class="task-assignment-assignee-picker__add-button"
    [type]="ButtonType.Secondary"
    [size]="ButtonSize.Small"
    [disabled]="previewDataLoading()"
    (click)="openSelectDialog()"
  >
    <i
      [class.icon-edit-pencil]="hasAudience()"
      [class.icon-add]="!hasAudience()"
      slot="start-icon"
    >
    </i>

    @if (hasAudience()) {
      {{ 'assigneePicker.editAssignees' | translate }}
    } @else {
      {{ 'assigneePicker.addAssignees' | translate }}
    }
  </app-button>
</div>
